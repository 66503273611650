<template>
  <BaseCard padding="0px" overflow="visible" v-bind:id="structureItem.dataId">
    <div
      class="advanced-block"
      v-bind:class="{
        active: activeBlock && activeBlock.dataId === structureItem.dataId,
      }"
    >
      <div
        class="advanced-block__quick-controls"
        v-show="
          activeBlock &&
          activeBlock.dataId === structureItem.dataId &&
          !disabled &&
          !prefilledMode
        "
      >
        <div
          class="advanced-block__quick-controls__button"
          v-show="blockIndex !== 0 && blocksCount > 1 && !disabled"
          v-on:click="moveUpFn"
        >
          <font-awesome-icon icon="arrow-up" v-once />
        </div>
        <div
          class="advanced-block__quick-controls__button"
          v-show="blockIndex !== blocksCount - 1 && !disabled"
          v-on:click="moveDownFn"
        >
          <font-awesome-icon icon="arrow-down" v-once />
        </div>
        <div
          class="advanced-block__quick-controls__button"
          v-show="!disabled"
          v-on:click="deleteFN"
        >
          <font-awesome-icon icon="trash" v-once />
        </div>
      </div>
      <div
        class="advanced-block__body"
        v-for="(obj, name) in values.fields"
        :key="name"
      >
        <CollapsibleContentLight
          v-if="
            !schema ||
            (schema && !schema[name]) ||
            (schema && schema[name] && schema[name].editable)
          "
          :wrap="typeof obj.optional !== 'undefined' && obj.optional === true"
          :title="obj.label || name"
          :content-padding="'15px 0px 15px 0px'"
          :button-bg-color="`${colorsHtml.background}`"
          :button-padding="'7px 15px'"
          :collapse-id="JSON.stringify(obj)"
          :margin-bottom="'15px'"
        >
          <div
            class="item"
            v-if="
              obj.datatype === 'text' &&
              (!obj.location || obj.location === 'block')
            "
          >
            <FormulateInput
              type="text"
              :name="name"
              :disabled="disabled"
              v-bind:value="obj.default"
              v-bind:label="obj.label"
              v-bind:class="editableClass"
              :ref="name"
              v-on:keyup="updateFieldData($event.target.value, name)"
            />
          </div>

          <div
            class="item"
            v-if="
              obj.datatype === 'number' &&
              (!obj.location || obj.location === 'block')
            "
          >
            <FormulateInput
              type="number"
              :name="name"
              :disabled="disabled"
              v-bind:value="obj.default"
              v-bind:label="obj.label"
              v-bind:class="editableClass"
              :ref="name"
              v-on:keyup="updateFieldData($event.target.value, name)"
            />
          </div>

          <div
            class="item"
            v-if="
              obj.datatype === 'checkbox' &&
              (!obj.location || obj.location === 'block')
            "
          >
            <FormulateInput
              type="checkbox"
              :name="name"
              :disabled="disabled"
              v-bind:value="obj.default"
              v-bind:checked="obj.default"
              v-bind:label="obj.label"
              v-bind:class="editableClass"
              :ref="name"
              v-on:change="updateFieldData($event.target.checked, name)"
            />
          </div>

          <div
            class="item"
            v-if="
              obj.datatype === 'date' &&
              (!obj.location || obj.location === 'block')
            "
          >
            <FormulateInput
              type="date"
              :name="name"
              :disabled="disabled"
              v-bind:value="obj.default"
              v-bind:checked="obj.default"
              v-bind:label="obj.label"
              v-bind:class="editableClass"
              :ref="name"
              v-on:change="updateFieldData($event.target.value, name)"
            />
          </div>

          <div
            class="item"
            v-if="
              obj.datatype === 'select' &&
              (!obj.location || obj.location === 'block')
            "
          >
            <FormulateInput
              :disabled="disabled"
              type="select"
              :name="name"
              :options="obj.model ? advancedBlock[obj.model] : advancedBlockobj.options"
              v-bind:value="obj.default"
              v-bind:label="obj.label"
              v-bind:class="editableClass"
              :ref="name"
              v-on:change="updateFieldData($event.target.value, name)"
            />
          </div>

          <div
            class="item"
            v-if="
              obj.datatype === 'richtext' &&
              (!obj.location || obj.location === 'block')
            "
          >
            <RichTextEditor
              :editable="!disabled"
              :key="name"
              v-bind:class="editableClass"
              :bodyTitle="obj.label"
              :value="obj.default"
              v-on:change-input="updateFieldData($event, name)"
            />
          </div>

          <div
            class="item"
            v-if="
              obj.datatype === 'list' &&
              (!obj.location || obj.location === 'block')
            "
          >
            <List
              :editable="!disabled"
              v-bind:value="obj.default"
              v-bind:label="obj.label"
              v-bind:object="obj.object"
              :ref="name"
              v-on:list-changed="updateFieldData($event, name)"
            />
          </div>

          <div
            class="item"
            v-if="
              obj.datatype === 'image' &&
              (!obj.location || obj.location === 'block')
            "
          >
            <ImageInput
              :editable="!disabled"
              :label="obj.label"
              :value="obj.default"
              :url="fileUrl(obj.default)"
              :name="name"
              :ref="name"
              v-on:image-changed="updateFieldData($event, name)"
            />
          </div>

          <div
            class="item"
            v-if="
              obj.datatype === 'file' &&
              (!obj.location || obj.location === 'block')
            "
          >
            <FileInput
              :editable="!disabled"
              :label="obj.label"
              :value="obj.default"
              :url="fileUrl(obj.default)"
              :name="name"
              :ref="name"
              v-on:file-changed="updateFieldData($event, name)"
            />
          </div>
          <div
            class="item"
            v-if="
              obj.datatype === 'color' &&
              (!obj.location || obj.location === 'block')
            "
          >
            <FormulateInput
              :disabled="disabled"
              type="color"
              :name="name"
              v-bind:value="obj.default"
              v-bind:label="obj.label"
              :ref="name"
              v-on:change="updateFieldData($event, name)"
            />
          </div>
          <div class="item inner-blocks" v-if="obj.datatype === 'innerblocks'">
            <div class="drop-area" v-if="!disabled && !prefilledMode">
              <CircleButton />
              <div
                v-bind:id="`${structureItem.dataId}-drop-area__0`"
                v-on:dragover="functions.allowDrop"
                v-on:dragleave="functions.dragLeave"
                v-on:drop="functions.drop"
                class="drop-area__mask"
              ></div>
            </div>
            <div
              class="nested-blocks-container"
              v-if="
                structureItem.children &&
                Object.values(structureItem.children).length
              "
            >
              <div
                class="nested-item"
                v-for="(nestedItem, nestedBlockIndex) in Object.values(
                  structureItem.children
                )"
                v-bind:key="nestedBlockIndex"
              >
                <div
                  :blockid="nestedItem.dataId"
                  v-on:dragstart="functions.onDragging"
                  v-on:dragend="functions.dragEnd"
                  v-bind:draggable="true"
                  v-on:click="activateBlockFn($event)"
                  v-bind:transfer-id="`${nestedItem.component}__${nestedBlockIndex}`"
                  class="block-nested-item"
                >
                  <AdvancedBlock
                    v-bind:structure-item="nestedItem"
                    v-bind:block-object="blocksList[nestedItem.component]"
                    v-bind:block-values="data[nestedItem.dataId]"
                    v-bind:block-slots="blockSlots"
                    v-bind:key="nestedBlockIndex + nestedItem.dataId"
                    v-bind:structure="Object.values(structureItem.children)"
                    v-bind:block-index="parseInt(nestedBlockIndex)"
                    v-bind:disabled="disabled"
                    v-bind:blocksList="blocksList"
                    v-bind:data="data"
                    v-bind:parent="String(structureItem.dataId)"
                    v-bind:functions="functions"
                    v-bind:activateBlockFn="activateBlockFn"
                    :prefilled-mode="prefilledMode"
                  />
                </div>
                <div class="drop-area" v-if="!disabled && !prefilledMode">
                  <CircleButton />
                  <div
                    v-bind:id="`${structureItem.dataId}-drop-area__${
                      nestedBlockIndex + 1
                    }`"
                    v-on:dragover="functions.allowDrop"
                    v-on:dragleave="functions.dragLeave"
                    v-on:drop="functions.drop"
                    class="drop-area__mask"
                  ></div>
                </div>
                <div v-else class="block-editor-spacer"></div>
              </div>
            </div>
          </div>
        </CollapsibleContentLight>
      </div>
      <div class="advanced-block__footer" v-if="blockObject">
        {{ blockObject.name }}
      </div>
    </div>
  </BaseCard>
</template>

<script>
import { mapState, mapMutations } from "vuex";

import { library } from "@fortawesome/fontawesome-svg-core";

import { debounce } from "@/services/debounce";

import {
  faArrowUp,
  faArrowDown,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import colorsHtml from "/colors.config.json";
library.add(faArrowUp, faArrowDown, faTrash);
export default {
  name: "AdvancedBlock",
  components: {
    BaseCard: () => import("@/components/Cards/BaseCard.vue"),
    List: () => import("@/components/List/List"),
    RichTextEditor: () => import("@/components/TextEditor/RichTextEditor"),
    ImageInput: () => import("../Input/ImageInput.vue"),
    FileInput: () => import("../Input/FileInput.vue"),
    CircleButton: () => import("@/components/Buttons/CircleButton"),
    CollapsibleContentLight: () =>
      import("@/components/Collapsible/CollapsibleContentLight"),
    AdvancedBlock: () => import("@/components/Blocks/AdvancedBlock"), //nesting
  },
  props: {
    blockObject: {
      type: Object,
    },
    blockValues: {
      type: Object,
    },
    blockSlots: {
      type: [Object, Array],
    },
    blocksList: {
      type: Object,
    },
    data: {
      type: Object,
    },
    structureItem: {
      type: Object,
    },
    onDragging: {
      type: Function,
    },
    blockIndex: {
      type: Number,
    },
    structure: {
      type: Array,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    functions: {
      type: Object,
    },
    parent: {
      type: String,
      default: "",
    },
    activateBlockFn: {
      type: Function,
    },
    prefilledMode: {
      type: Boolean,
    },
    schema: {
      type: Object,
    },
  },
  data() {
    return {
      colorsHtml: colorsHtml,
    };
  },
  methods: {
    ...mapMutations("blockEditor", {
      deleteBlockFromStructure: "deleteBlockFromStructure",
      updateActiveBlock: "updateActiveBlock",
      updateStructure: "updateStructure",
      updateData: "updateData",
      updateSlot: "updateSlot",
    }),
    updateFieldData(val, key) {
      debounce(
        () => {
          if (this.activeBlock.kind !== "slot") {
            this.updateData({
              dataId: this.structureItem.dataId,
              value: val,
              key: key,
            });
          } else {
            this.updateSlot({ dataId: this.activeBlock.slotId, value: val });
          }
        },
        500,
        this.structureItem.dataId + key
      );
    },
    deleteFN() {
      this.$emit("detele-advanced-block", this.activeBlock.dataId);
      this.deleteBlockFromStructure(this.activeBlock.dataId);
      this.updateActiveBlock({});
    },
    moveUpFn() {
      let structure = this.structure;
      if (Array.isArray(structure) && structure.length > 0) {
        if (
          structure[this.blockIndex] &&
          structure[this.blockIndex].dataId === this.activeBlock.dataId
        ) {
          if (this.blockIndex - 1 > -1) {
            let temp = structure[this.blockIndex - 1];

            structure[this.blockIndex - 1] = structure[this.blockIndex];
            structure[this.blockIndex] = temp;

            this.updateStructure({
              data: structure,
              absolute: true,
              parent: this.parent,
            });
          }
        }
      }
    },
    moveDownFn() {
      let structure = this.structure;

      if (Array.isArray(structure) && structure.length > 0) {
        if (
          structure[this.blockIndex] &&
          structure[this.blockIndex].dataId === this.activeBlock.dataId
        ) {
          if (this.blockIndex + 1 < this.blocksCount) {
            let temp = structure[this.blockIndex + 1];

            structure[this.blockIndex + 1] = structure[this.blockIndex];
            structure[this.blockIndex] = temp;

            this.updateStructure({
              data: structure,
              absolute: true,
              parent: this.parent,
            });
          }
        }
      }
    },
    fileUrl(file) {
      if (typeof file === "object" && file.name) {
        return URL.createObjectURL(new Blob([file], { type: file.type }));
      }
      if (typeof file === "string" && file !== "") {
        return window.location.origin + file;
      }

      return "";
    },
  },
  computed: {
    ...mapState("blockEditor", {
      activeBlock: "activeBlock",
      singleEntity: "singleEntity",
    }),
    ...mapState( 'helper', ['advancedBlock'] ),
    blocksCount() {
      return this.structure.length;
    },
    editableClass() {
      if (this.disabled) {
        return "not-editable";
      }
      return "";
    },
    values() {
      let blockObject = {};

      if (this.blockObject && (this.blockValues || this.blockSlots)) {
        blockObject = JSON.parse(JSON.stringify(this.blockObject));

        if (this.structureItem.kind !== "slot") {
          for (let prop in this.blockValues) {
            if (blockObject.fields[prop]) {
              blockObject.fields[prop].default = this.blockValues[prop];
              if (
                blockObject.fields[prop].datatype === "list" &&
                blockObject.fields[prop].default === ""
              ) {
                blockObject.fields[prop].default = [];
              }
            }
          }

          // assign default values to state so they will be saved
          for (let prop in blockObject.fields) {
            if (
              blockObject.fields[prop] &&
              blockObject.fields[prop].default &&
              this.blockValues &&
              !this.blockValues[prop]
            ) {
              this.updateData({
                dataId: this.structureItem.dataId,
                value: blockObject.fields[prop].default,
                key: prop,
              });
              this.$emit("set-default-settings", {
                key: prop,
                value: blockObject.fields[prop].default,
                dataId: this.structureItem.dataId,
              });
            }
          }
        } else {
          for (let prop in this.blockObject.fields) {
            blockObject.fields[prop].default =
              this.blockSlots[this.structureItem.dataId];

            if (
              blockObject.fields[prop].datatype === "list" &&
              blockObject.fields[prop].default === ""
            ) {
              blockObject.fields[prop].default = [];
            }
          }
        }
      } else if (this.blockObject && !this.blockValues) {
        blockObject = this.blockObject;
      }

      return blockObject;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

::v-deep label {
  margin-bottom: 0px;
}
::v-deep .not-editable input,
::v-deep .not-editable .editor,
::v-deep .not-editable .editor__content,
::v-deep .not-editable select {
  border: none !important;
  padding: 0px;
}
.block-editor-spacer {
  width: 100%;
  height: 25px;
}
.inner-blocks {
  .block-nested-item + .drop-area {
    margin-top: 30px;
  }
  .drop-area {
    transition: 0.3s;
    position: relative;
    padding-bottom: 30px;
    padding-top: 30px;
    background: $background;
    margin-bottom: 5px;
    border: 1px dashed $light_text;
    border-radius: $border_radius;

    &__line {
      height: 4px;
      background-color: $light_gray;
    }

    .add-button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &__mask {
      position: absolute;
      opacity: 0.5;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }
}
.advanced-block {
  position: relative;
  padding: 30px 15px;
  transition: 0.3s;
  border-radius: 4px;
  border: 1px solid transparent;

  &.active {
    border: 1px solid $saturated_green;
    box-sizing: border-box;
  }

  ::v-deep {
    .formulate-input-label {
      font-size: 1rem;
      margin: 0 0 10px;
      font-weight: bold;
      font-family: "Lato";
    }
    .formulate-input-element > h4 {
      font-size: 1rem;
      margin: 0 0 10px;
      font-weight: bold;
      padding: 0;
      font-family: "Lato";
    }

    .formulate-input[data-type="checkbox"] {
      .formulate-input-label--after {
        margin-bottom: 0px;
        margin-left: 5px;
      }
    }
  }

  .advanced-block__body {
    .collapsible-content {
      .item {
        margin-bottom: 0px;
        // padding: 20px;
      }
    }
  }

  &__quick-controls {
    position: absolute;
    right: 15px;
    top: -19px;
    min-width: 38px;
    height: 38px;
    background: $background;
    border: 1px solid $light_gray;
    border-radius: 5px;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;

    &__button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35px;
      height: 35px;
      border-radius: 5px;
      color: $light_text;
      transition: all 0.3s ease;
      cursor: pointer;
      position: relative;

      &:not(:last-of-type)::after {
        position: absolute;
        content: "";
        right: 0;
        width: 1px;
        height: calc(100% - 10px);
        top: 50%;
        transform: translateY(-50%);
        background: $light_gray;
      }
    }

    &__button:hover {
      opacity: 0.8;
    }
  }

  &__body {
    .item {
      border-bottom: 1px solid #bdc3c7;
      margin-bottom: 10px;
      padding-bottom: 10px;

      &:last-child {
        border: none;
      }
    }
    ::v-deep {
      .collapsible-content_header {
        &:last-child {
          border-bottom: unset;
        }
      }

      .formulate-input-element,
      .formulate-input {
        max-width: 100%;
      }
    }
  }

  &__footer {
    border-top: 1px solid $light_gray;
    text-align: right;
    padding: 15px 0 0;
    margin: 15px 0 0;
    font-family: "Lato";
    font-weight: bold;
    font-size: 0.875rem;
    line-height: 200%;
    color: $dark;
  }
}
</style>
