var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseCard',{attrs:{"padding":"0px","overflow":"visible","id":_vm.structureItem.dataId}},[_c('div',{staticClass:"advanced-block",class:{
      active: _vm.activeBlock && _vm.activeBlock.dataId === _vm.structureItem.dataId,
    }},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.activeBlock &&
        _vm.activeBlock.dataId === _vm.structureItem.dataId &&
        !_vm.disabled &&
        !_vm.prefilledMode
      ),expression:"\n        activeBlock &&\n        activeBlock.dataId === structureItem.dataId &&\n        !disabled &&\n        !prefilledMode\n      "}],staticClass:"advanced-block__quick-controls"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.blockIndex !== 0 && _vm.blocksCount > 1 && !_vm.disabled),expression:"blockIndex !== 0 && blocksCount > 1 && !disabled"}],staticClass:"advanced-block__quick-controls__button",on:{"click":_vm.moveUpFn}},[_vm._m(0)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.blockIndex !== _vm.blocksCount - 1 && !_vm.disabled),expression:"blockIndex !== blocksCount - 1 && !disabled"}],staticClass:"advanced-block__quick-controls__button",on:{"click":_vm.moveDownFn}},[_vm._m(1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.disabled),expression:"!disabled"}],staticClass:"advanced-block__quick-controls__button",on:{"click":_vm.deleteFN}},[_vm._m(2)],1)]),_vm._l((_vm.values.fields),function(obj,name){return _c('div',{key:name,staticClass:"advanced-block__body"},[(
          !_vm.schema ||
          (_vm.schema && !_vm.schema[name]) ||
          (_vm.schema && _vm.schema[name] && _vm.schema[name].editable)
        )?_c('CollapsibleContentLight',{attrs:{"wrap":typeof obj.optional !== 'undefined' && obj.optional === true,"title":obj.label || name,"content-padding":'15px 0px 15px 0px',"button-bg-color":("" + (_vm.colorsHtml.background)),"button-padding":'7px 15px',"collapse-id":JSON.stringify(obj),"margin-bottom":'15px'}},[(
            obj.datatype === 'text' &&
            (!obj.location || obj.location === 'block')
          )?_c('div',{staticClass:"item"},[_c('FormulateInput',{ref:name,refInFor:true,class:_vm.editableClass,attrs:{"type":"text","name":name,"disabled":_vm.disabled,"value":obj.default,"label":obj.label},on:{"keyup":function($event){return _vm.updateFieldData($event.target.value, name)}}})],1):_vm._e(),(
            obj.datatype === 'number' &&
            (!obj.location || obj.location === 'block')
          )?_c('div',{staticClass:"item"},[_c('FormulateInput',{ref:name,refInFor:true,class:_vm.editableClass,attrs:{"type":"number","name":name,"disabled":_vm.disabled,"value":obj.default,"label":obj.label},on:{"keyup":function($event){return _vm.updateFieldData($event.target.value, name)}}})],1):_vm._e(),(
            obj.datatype === 'checkbox' &&
            (!obj.location || obj.location === 'block')
          )?_c('div',{staticClass:"item"},[_c('FormulateInput',{ref:name,refInFor:true,class:_vm.editableClass,attrs:{"type":"checkbox","name":name,"disabled":_vm.disabled,"value":obj.default,"checked":obj.default,"label":obj.label},on:{"change":function($event){return _vm.updateFieldData($event.target.checked, name)}}})],1):_vm._e(),(
            obj.datatype === 'date' &&
            (!obj.location || obj.location === 'block')
          )?_c('div',{staticClass:"item"},[_c('FormulateInput',{ref:name,refInFor:true,class:_vm.editableClass,attrs:{"type":"date","name":name,"disabled":_vm.disabled,"value":obj.default,"checked":obj.default,"label":obj.label},on:{"change":function($event){return _vm.updateFieldData($event.target.value, name)}}})],1):_vm._e(),(
            obj.datatype === 'select' &&
            (!obj.location || obj.location === 'block')
          )?_c('div',{staticClass:"item"},[_c('FormulateInput',{ref:name,refInFor:true,class:_vm.editableClass,attrs:{"disabled":_vm.disabled,"type":"select","name":name,"options":obj.model ? _vm.advancedBlock[obj.model] : _vm.advancedBlockobj.options,"value":obj.default,"label":obj.label},on:{"change":function($event){return _vm.updateFieldData($event.target.value, name)}}})],1):_vm._e(),(
            obj.datatype === 'richtext' &&
            (!obj.location || obj.location === 'block')
          )?_c('div',{staticClass:"item"},[_c('RichTextEditor',{key:name,class:_vm.editableClass,attrs:{"editable":!_vm.disabled,"bodyTitle":obj.label,"value":obj.default},on:{"change-input":function($event){return _vm.updateFieldData($event, name)}}})],1):_vm._e(),(
            obj.datatype === 'list' &&
            (!obj.location || obj.location === 'block')
          )?_c('div',{staticClass:"item"},[_c('List',{ref:name,refInFor:true,attrs:{"editable":!_vm.disabled,"value":obj.default,"label":obj.label,"object":obj.object},on:{"list-changed":function($event){return _vm.updateFieldData($event, name)}}})],1):_vm._e(),(
            obj.datatype === 'image' &&
            (!obj.location || obj.location === 'block')
          )?_c('div',{staticClass:"item"},[_c('ImageInput',{ref:name,refInFor:true,attrs:{"editable":!_vm.disabled,"label":obj.label,"value":obj.default,"url":_vm.fileUrl(obj.default),"name":name},on:{"image-changed":function($event){return _vm.updateFieldData($event, name)}}})],1):_vm._e(),(
            obj.datatype === 'file' &&
            (!obj.location || obj.location === 'block')
          )?_c('div',{staticClass:"item"},[_c('FileInput',{ref:name,refInFor:true,attrs:{"editable":!_vm.disabled,"label":obj.label,"value":obj.default,"url":_vm.fileUrl(obj.default),"name":name},on:{"file-changed":function($event){return _vm.updateFieldData($event, name)}}})],1):_vm._e(),(
            obj.datatype === 'color' &&
            (!obj.location || obj.location === 'block')
          )?_c('div',{staticClass:"item"},[_c('FormulateInput',{ref:name,refInFor:true,attrs:{"disabled":_vm.disabled,"type":"color","name":name,"value":obj.default,"label":obj.label},on:{"change":function($event){return _vm.updateFieldData($event, name)}}})],1):_vm._e(),(obj.datatype === 'innerblocks')?_c('div',{staticClass:"item inner-blocks"},[(!_vm.disabled && !_vm.prefilledMode)?_c('div',{staticClass:"drop-area"},[_c('CircleButton'),_c('div',{staticClass:"drop-area__mask",attrs:{"id":((_vm.structureItem.dataId) + "-drop-area__0")},on:{"dragover":_vm.functions.allowDrop,"dragleave":_vm.functions.dragLeave,"drop":_vm.functions.drop}})],1):_vm._e(),(
              _vm.structureItem.children &&
              Object.values(_vm.structureItem.children).length
            )?_c('div',{staticClass:"nested-blocks-container"},_vm._l((Object.values(
                _vm.structureItem.children
              )),function(nestedItem,nestedBlockIndex){return _c('div',{key:nestedBlockIndex,staticClass:"nested-item"},[_c('div',{staticClass:"block-nested-item",attrs:{"blockid":nestedItem.dataId,"draggable":true,"transfer-id":((nestedItem.component) + "__" + nestedBlockIndex)},on:{"dragstart":_vm.functions.onDragging,"dragend":_vm.functions.dragEnd,"click":function($event){return _vm.activateBlockFn($event)}}},[_c('AdvancedBlock',{key:nestedBlockIndex + nestedItem.dataId,attrs:{"structure-item":nestedItem,"block-object":_vm.blocksList[nestedItem.component],"block-values":_vm.data[nestedItem.dataId],"block-slots":_vm.blockSlots,"structure":Object.values(_vm.structureItem.children),"block-index":parseInt(nestedBlockIndex),"disabled":_vm.disabled,"blocksList":_vm.blocksList,"data":_vm.data,"parent":String(_vm.structureItem.dataId),"functions":_vm.functions,"activateBlockFn":_vm.activateBlockFn,"prefilled-mode":_vm.prefilledMode}})],1),(!_vm.disabled && !_vm.prefilledMode)?_c('div',{staticClass:"drop-area"},[_c('CircleButton'),_c('div',{staticClass:"drop-area__mask",attrs:{"id":((_vm.structureItem.dataId) + "-drop-area__" + (nestedBlockIndex + 1))},on:{"dragover":_vm.functions.allowDrop,"dragleave":_vm.functions.dragLeave,"drop":_vm.functions.drop}})],1):_c('div',{staticClass:"block-editor-spacer"})])}),0):_vm._e()]):_vm._e()]):_vm._e()],1)}),(_vm.blockObject)?_c('div',{staticClass:"advanced-block__footer"},[_vm._v(" "+_vm._s(_vm.blockObject.name)+" ")]):_vm._e()],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('font-awesome-icon',{attrs:{"icon":"arrow-up"}})},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('font-awesome-icon',{attrs:{"icon":"arrow-down"}})},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('font-awesome-icon',{attrs:{"icon":"trash"}})}]

export { render, staticRenderFns }